import { global } from './global'
// import {store} from '@/store'

let _baseurl = global.baseApiUrl + 'empleado/'

export default {
  getCortes(empleadoID) {
    return global.axios.get(_baseurl + 'get-cortes?empleadoID=' + empleadoID)
  },
  getCortesPorCanales(canales) {
    return global.axios.post(_baseurl + 'get-cortes-por-canales', canales)
  },
  getEmpleados(corteID, empleadoID) {

    var id = empleadoID?empleadoID:0;

    return global.axios.get(_baseurl + 'get-empleados?corteID=' + corteID + '&empleadoID=' + id)
  },
  getEmpleadosByCanal(canalID) {
    return global.axios.get(_baseurl + 'get-empleados-by-canal?canalID=' + canalID)
  },
  getSegmento(canales) {
    return global.axios.post(_baseurl + 'get-segmento', canales)
  },
  getGruposComision(canales) {
    return global.axios.post(_baseurl + 'get-grupos-comision', canales)
  },
  getAsociadosPorCanales(param) {
    return global.axios.post(_baseurl + 'get-asociados-por-canales', param)
  },
  getGestionado(empleadoID) {
    return global.axios.get(_baseurl + 'get-gestionado?empleadoID=' + empleadoID)
  },
  getCanalesGestionado(empleadoID) {
    return global.axios.get(_baseurl + 'get-canales-gestionado?empleadoID=' + empleadoID)
  },
  getEmpleadoIncidencia(empleadoID,corteID) {
    return global.axios.get(_baseurl + 'get-empleados-Incidencia?empleadoID=' + empleadoID + '&corteID=' + corteID)
  },
  getCanal(canalId) {
    return global.axios.get(_baseurl + 'get-canal?canalId=' + canalId)
  },
}